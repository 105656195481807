<template>
  <div class="section is-small" v-if="hasGroups">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <div class="buttons">
            <button class="button is-info is-light" @click="$emit('expand')">
              <span class="icon">
                <i
                  class="fad"
                  :class="[
                    expanded ? 'fa-chevron-double-up' : 'fa-chevron-double-down'
                  ]"
                ></i>
              </span>
              <span>
                Toggle search
              </span>
            </button>
          </div>
        </div>
        <div class="column is-narrow" v-if="hasGroups">
          <label class="title is-6 has-text-grey-light">
            <span class="icon has-text-success">
              <i class="fad fa-tasks"></i>
            </span>
            <span>
              {{ request.length }} of {{ groups.length }} groups selected
            </span>
          </label>
        </div>
        <div class="column is-narrow">
          <button
            class="button is-soft is-rounded"
            :class="{ 'is-loading': $wait.is('confirm') }"
            :disabled="!hasSearched || $wait.is('confirm') || !request.length"
            @click="submit"
            v-if="!listings && hasGroups"
          >
            <span class="icon">
              <i class="fad fa-check"></i>
            </span>
            <span>
              Confirm
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'ExplorerGroupConfirmation',
  props: {
    expanded: { type: Boolean, required: true }
  },
  computed: {
    ...mapState('explorer', ['groups', 'form', 'listings']),
    ...mapGetters('explorer', ['hasGroups', 'hasSearched', 'request'])
  },
  methods: {
    ...mapActions('explorer', ['getListings']),
    ...mapMutations('explorer', ['setRequest']),
    async submit() {
      this.$wait.start('confirm')

      try {
        const params = pick(this, ['form', 'request'])
        const nextRequest = []

        params.request.forEach((requestIndex, index) => {
          nextRequest.push(requestIndex)
          params.request[index] = this.groups[requestIndex]
        })

        this.setRequest(nextRequest)

        this.$mxp.track('explorer_listings_request', { params })
        await this.getListings(params)
      } catch (err) {
        this.$mxp.track('explorer_listings_request_failed', { err })
        this.$notify('Unable to fetch listings')
      } finally {
        this.$wait.end('confirm')
      }
    }
  }
}
</script>
